<template>
    <header>
        <div class="logo"></div>
        <div class="img header-logo">
            <!-- <video class="onlyDesktop" src="/images/header.mp4" autoplay muted loop></video> -->
            <!-- <video class="onlyMobile" src="/images/header_mobile.mp4" autoplay muted loop></video> -->
        </div>
    </header>
</template>

<script>
    export default {
        name: "header-component"
    }
</script>

<style lang="scss">
    header {
        position: relative;
        width: 100%;
        height: 32.44vw; //50vw;

        .logo {
            display: none;
            position: fixed;
            background-image: url('/images/mht-logo-desktop.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 4.01vw;
            height: 3.33vw;
            top: 28px;
            left: 27px;
            z-index: 1;

            @media all and (min-width: $screen-desktop-min-width) and (max-width: $screen-desktop-max-width) {
                width: 5.63vw;
                height: 4.68vw;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                width: 4.68vw;
                height: 4.81vw;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 9.6vw;
                height: 9.6vw;
                position: absolute;
                z-index: 2;
            }

            @media all  and (max-width: $screen-tablet-max-width) {
                background-image: url('/images/mht-logo.svg');
            }
        }

        .img {
            position: absolute;
            width: 100%;
            background-image: url('/images/header2880.jpg');
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: 1;

            @media all and (min-width: $screen-desktop-min-width) and (max-width: $screen-desktop-max-width) {
                background-image: url('/images/header1920.jpg');
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                background-image: url('/images/header1366.jpg');
            }

            @media all and (max-width: $screen-phone-max-width) {
                background-image: url('/images/header375.png');
            }
        }

        .header-logo {
            bottom: 0;
            height: 32.4vw;

            video {
                height: 100%;
                width: 100%;
            }

            @media all and (min-width: $screen-desktop-min-width) and (max-width: $screen-desktop-max-width) {
                height: 32.43vw;
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                height: 32.42vw;
            }

            @media all and (max-width: $screen-phone-max-width) {
                height: 100vw;
            }
        }

        @media all and (min-width: $screen-desktop-min-width) {
            .onlyMobile {
                display: none;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            height: 32.42vw;//63.8vw;

            .onlyMobile {
                display: none;
            }
        }

        @media all and (max-width: $screen-phone-max-width) {
            height: 100vw; //148.5vw;

            .onlyDesktop {
                display: none;
            }
        }

    }
</style>