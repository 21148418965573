<template>
    <div class="contact">
        <span class="type" v-html="type"></span>
        <a :href="`mailto:${email}`" v-html="email"></a>
    </div>
</template>

<script>
    export default {
        name: "contact",
        props: ['type', 'email']
    }
</script>

<style lang="scss">
    .contact {
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;

        a, .type {
            display: block;
        }

        a {
            font-size: 40px;
            font-weight: 900;
            margin-bottom: 40px;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 5.33vw;
                margin-bottom: 20px;
            }
        }

        .type {
            font-size: 20px;
            font-weight: normal;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 5.33vw;
                margin-bottom: 5px;
            }
        }
    }
</style>