<template>
    <div class="section-header">
        <img class="header-line" :src="lineUrl" v-if="hasLine && lineUrl">
        <img class="header-landmark" v-if="hasLandmark" src="/images/landmark.svg">
        <h1 v-html="title"></h1>
    </div>
</template>

<script>
    export default {
        name: "section-header",
        props: ['sectionPrefix', 'title', 'hasLandmark', 'hasLine'],
        computed: {
            lineUrl() {
                return (this.widthBase && `/images/${this.sectionPrefix}-line${this.widthBase}.svg`) || null;
            }
        }
    }
</script>

<style lang="scss">
    .section-header {
        h1 {
            position: relative;
            text-transform: uppercase;
            font-size: 80px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #00c5f1;
            line-height: 121px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                font-size: 60px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 9.33vw;//35px;
                line-height: 18.66vw;//70px;
            }
        }

        .header-landmark {
            @media all and (max-width: $screen-phone-max-width) {
                width: 8.8vw;
                heught: 12vw;
            }
        }

        @media all and (max-width: $screen-phone-max-width) {
            position: relative;
        }
    }
</style>