<template>
    <div class="instruction">
        <img src="/images/instruction-landmark.svg">
        <p v-html="text"></p>
    </div>
</template>

<script>
    export default {
        name: "instruction-point",
        props: ['text']
    }
</script>

<style lang="scss">
    .instruction {
        position: absolute;

        img {
            position: absolute;
            width: 41px;
            height: 58px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                width: 24px;
                height: 34px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 6.4vw;
                height: 9.06vw;
            }
        }

        p {
            font-size: 36px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 46px;
            letter-spacing: normal;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                font-size: 25px;
                line-height: 30px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 6.66vw;
                line-height: 8vw;
            }
        }
    }
</style>