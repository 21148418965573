<template>
    <section class="partners">
        <section-header :title="title" :hasLandmark="false" :hasLine="true" :sectionPrefix="`partners`"></section-header>
        <div class="partners-wrapper">
            <div class="logos logos_bottomspace">
                <a href="https://plus.yandex.ru" target="_blank"><img class="logo logo_yandex" src="/images/yandex-plus.png"></a>
                <a href="https://discover.moscow/" target="_blank"><img class="logo logo_discover" src="/images/discover-moscow.svg"></a>
            </div>
            <p class="extraLogosSection">Информационные партнеры:</p>
            <div class="logos">
                <a href="https://www.cosmo.ru/" target="_blank"><img class="logo logo_cosmo" src="/images/cosmopolitan-white.svg"></a>
                <a href="https://digitalreporter.ru/" target="_blank"><img class="logo logo_reporter" src="/images/digital-reporter.svg"></a>
            </div>
        </div>
    </section>
</template>

<script>
    import SectionHeader from '~/components/section-header/section-header.vue'

    export default {
        name: "partners",
        components: {
            SectionHeader
        },
        data() {
            let basePath = 'components.partners';
            let title = this.getLocalized(basePath, 'title');
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                title,
                items
            }
        }
    }
</script>

<style lang="scss">

    .extraLogosSection {
        line-height: 1;
        margin-bottom: 32px;
        margin-top: 65px;
    }
    
    .logos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .logo {
            display: block;
            margin-bottom: 18px;
        }

        .logo:nth-of-type(1) {
            margin-right: 116px;
        }

        .logo_cosmo {
            width: 348px;
        }

        .logo_reporter {
            height: 64px;
        }

        .logo_yandex {
            width: 293px;
        }

        .logo_discover {
            width: 176px;
        }

        @media screen and (max-width: 767px) {
            .logo {
                margin: 24px 0;
            }

            .logo_cosmo {
                width: calc(348px * 0.7);
            }

            .logo_reporter {
                height: calc(64px * 0.7);
            }

            .logo_yandex {
                width: calc(293px * 0.7);
            }

            .logo_discover {
                width: calc(176px * 0.7);
            }
        }
    }
    
    .partners {
        margin-top: 100px !important;
        position: relative;

        @include section-header-line (35px, -78%, 35px, -51%, 25px, -55%, 0, -47%, 127.2vw, 14.66vw);

        .partners-wrapper {
            margin-top: 73px;
            line-height: 123px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                margin-top: 66px;
                line-height: unset;
                height: unset;

                .partner {
                    margin-left: 56px;

                    img {
                        width: unset;
                    }
                }

                .partner:nth-of-type(odd) {
                    margin-bottom: 76px;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: 0 !important;
                height: unset;
                line-height: unset;

                .partner {
                   width: 50vw;
                   margin-left: auto;
                   margin-right: auto;
                }

                .partner:nth-of-type(odd) {
                    width: 75vw;
                    margin-left: auto;
                    margin-right: auto;
                }
               
            }
        }
    }

</style>