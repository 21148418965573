<template>
    <div class="person-avatar">
        <img class="light" src="/images/avatar-light.png">
        <img class="photo" :src="photoUrl">
        <span class="name" v-html="name"></span>
        <span class="position" v-if="position" v-html="position"></span>
    </div>
</template>

<script>
    export default {
        name: "person-avatar",
        props: ['name', 'position', 'photoUrl', 'photoWidth', 'photoHeight'],
    }
</script>

<style lang="scss">
    .person-avatar {
        position: relative;
        width: 200px;

        .photo {
            position: relative;
            width: 100%;
        }

        .light {
            position: absolute;
            top: 0;
            left: 0;

            @media all and (max-width: $screen-phone-max-width) {
                width: 76vw;
            }
        }

        .name {
            font-size: 25px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            position: absolute;
            bottom: -22px;
            left: 0;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 6.55vw;
            }
        }

        .position {
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            position: absolute;
            bottom: -75px;
            left: 0;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 6.25vw;
                bottom: -22vw;
            }
        }
    }
</style>