<template>
    <section class="posters">
        <section-header :title="title" :hasLandmark="true" :hasLine="true" :sectionPrefix="`posters`"></section-header>
        <div class="posters-wrapper">
            <poster v-for="(poster, index) in items"
                     :key="index"
                     :posterUrl="poster.posterUrl"
                     :posterMobileUrl="poster.posterMobileUrl"
                     :posterMobileHeight="poster.posterMobileHeight"
                     :headerTop="poster.headerTop"
                     :title="poster.title"
                     :isStarted="poster.isStarted"
                     :writtenBy="poster.writtenBy"
                     :description="poster.description"
                     :ageRated="poster.ageRated"
                     :opening="poster.opening"
                     :theLink="poster.link"
                     >
            </poster>
        </div>
    </section>
</template>

<script>
    import Poster from '~/components/poster/poster.vue';
    import SectionHeader from '~/components/section-header/section-header.vue'

    export default {
        name: "posters",
        components: {
            Poster,
            SectionHeader
        },
        data() {
            let basePath = 'components.posters';
            return {
                title: this.getLocalized(basePath, 'title'),
                items: this.getLocalizedCollection(basePath, 'items')
            }
        }
    }
</script>

<style lang="scss">
    .posters {
        padding-top: 100px;
        position: relative;
        margin-top: 23px !important;

        @include section-header-line (100px, -75%, 100px, -48%, 100px, -42%, 0, -51%, 107.7vw, 14.66vw);
        @include section-header-landmark(5px, 480px, 0, 562px, 5px, 474px, -7vw, 47.77vw);

        .posters-wrapper {
            @include wrapped-flex;
            justify-content: space-between;
            margin-top: 23px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                flex-direction: column;
                justify-content: unset;
                width: 522px;
                margin: 0 auto;
            }

            @media all and (max-width: $screen-phone-max-width) {
                flex-direction: column;
                justify-content: unset;
            }
        }

        @media all and (max-width: $screen-phone-max-width) {
            padding-top: 67px;
        }
    }
</style>