<template>
    <section class="instructions">
        <img class="line" :src="linesUrl && linesUrl.line1">
        <img class="line" :src="linesUrl && linesUrl.line2">
        <instruction-point v-for="(point, index) in items" :key="index" :text="point.text"></instruction-point>
        <div class="shops-buttons">
            <a target="_blank" v-on:click="iosClick()" href="https://apps.apple.com/ru/app/apple-store/id1453025777">
                <img src="/images/app-store.svg">
            </a>
            <a target="_blank" v-on:click="googleBlockInfo()" href="https://play.google.com/store/apps/details?id=com.futurehistory.mht2">
                <img src="/images/google-play.svg">
            </a>
        </div>
    </section>
</template>

<script>
    import InstructionPoint from '~/components/instruction-point/instruction-point.vue';

    export default {
        name: "instructions",
        components: {
            InstructionPoint
        },
        data() {
            let basePath = 'components.instructions';
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                items
            }
        },
        computed: {
            linesUrl() {
                let widthBase = this.widthBase === 1366 ? 1919 : this.widthBase;
                return (widthBase && {
                    line1: `/images/instructions-line-1-${widthBase}.svg`,
                    line2: `/images/instructions-line-2-${widthBase}.svg`
                }) || null;
            }
        },
        methods: {
            googleBlockInfo() {
                // e.preventDefault();
                // console.log(1);
                // const text = "У нас возникли небольшие трудности с приложением на Android. Компания Google временно заморозила страницу приложения и покупки в нём. Стараемся разрешить эту проблему как можно скорее.\n\nВы можете установить приложение вручную через APK-файл. Извиняемся за накладку и благодарим интерес к проекту :)";
                // if (confirm(text)) {
                //     window.location.replace("/MHT-v1.0.51.apk")
                // }
                ga('send', 'event', 'downloadLink', 'android');
            },
            iosClick() {
                console.log(2);
                ga('send', 'event', 'downloadLink', 'ios');
            }
        }
    }
</script>

<style lang="scss">
    .instructions {
        position: relative;
        height: 445px;

        .line {
            position: absolute;
        }

        .line:nth-of-type(1) {
            top: 30px;
            left: 70px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 16px;
                left: 57px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 7.4vw;
                left: 5.8vw;
                width: 11.4vw;
                height: 80.8vw;
            }
        }

        .line:nth-of-type(2) {
            top: 80px;
            left: 515px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 40px;
                left: 311px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 97.8vw;
                left: 14.66vw;
                width: 3.7vw;
                height: 42.26vw;
            }
        }

        .instruction:nth-of-type(1) {
            top: 93px;

            img {
                top: -110px;
                left: 30px;
                z-index: 2;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    top: -72px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    top: -11.2vw;
                    left: -12vw;
                }
            }

            p {
                width: 299px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 175px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 46.6vw;
                }
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 61px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 7.2vw;
                left: 26.13vw;
            }
        }

        .instruction:nth-of-type(2) {
            top: 195px;
            left: 443px;

            img {
                top: -80px;
                left: 5px;
                z-index: 2;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    top: -69px;
                    left: 46px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    top: 0;
                    left: -18vw;
                }
            }

            p {
                width: 273px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 207px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 55.2vw;
                }
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 130px;
                left: 231px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 87vw;
                left: 26.13vw;
            }
        }

        .instruction:nth-of-type(3) {
            top: 93px;
            left: 760px;

            img {
                top: -80px;
                left: 15px;
                z-index: 2;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    top: -55px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    top: 0;
                    left: -11vw;
                }
            }

            p {
                width: 353px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 198px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 52.8vw;
                }
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                top: 65px;
                left: 444px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 142vw;
                left: 26.13vw;
            }
        }

        .shops-buttons {
            position: absolute;
            bottom: 0;

            a {
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                }

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    width: 160px;
                    height: 48px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    width: 42.68vw;
                    height: 12.8vw;
                }
            }

            a:nth-of-type(odd) {
                margin-bottom: 24px;

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    margin-bottom: 14px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    margin-bottom: 3.73vw;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                top: 37.86vw;
                left: 26.13vw;
                bottom: unset;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            height: 290px;
        }

        @media all and (max-width: $screen-phone-max-width) {
            height: 200vw;
        }
    }

</style>