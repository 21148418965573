<template>
    <section class="artists">
        <section-header :title="title" :hasLandmark="true" :hasLine="true" :sectionPrefix="`artists`"></section-header>
        <div class="members-wrapper">
            <person-avatar v-for="(member, index) in members"
                           :key="index"
                           :photoWidth="member.photoWidth"
                           :photoHeight="member.photoHeight"
                           :photoUrl="member.photoUrl"
                           :name="member.name">
            </person-avatar>
        </div>
    </section>
</template>

<script>
    import PersonAvatar from '~/components/person-avatar/person-avatar.vue';
    import SectionHeader from '~/components/section-header/section-header.vue'

    export default {
        name: "artists",
        components: {
            PersonAvatar,
            SectionHeader
        },
        data() {
            let basePath = 'components.artists';
            let title = this.getLocalized(basePath, 'title');
            let members = this.getLocalizedCollection(basePath, 'members');

            return {
                title,
                members
            }
        }
    }
</script>

<style lang="scss">
    .artists {
        margin-top: 182px !important;
        position: relative;

        @include section-header-line (-10px, -66%, 0, -37%, 0, -39%, 0, -40%, 148.5vw, 14.66vw);
        @include section-header-landmark(-120px, 665px, -110px, 680px, -120px, 531px, -8vw, 83.73vw);

        .members-wrapper {
            @include wrapped-flex;

            margin-top: 67px;
            align-items: flex-end;

            .person-avatar {
                margin-right: 70px;
                margin-bottom: 60px;

                @media all and (max-width: $screen-phone-max-width) {
                    margin: 0 auto 18.66vw auto;
                }
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                .person-avatar:nth-of-type(odd) {
                    margin-right: 80px;
                    margin-left: 56px;
                }

                .person-avatar:nth-of-type(even) {
                    margin-right: 0;
                }
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: 42px;
            }
        }

        @media all and (max-width: $screen-phone-max-width) {
            margin-top: 84px !important;
        }
    }
</style>