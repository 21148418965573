<template>
    <section class="poster">
        <a :href="theLink" target="_blank">
            <img class="posterPic" v-bind:src="this.posterUrl">
        </a>
        <h1 v-html="title" :style="headerStyle"></h1>
        <div class="info-block">
            <p class="written-by" v-for="item in writtenBy">
                <span v-html="item[0]"></span><br/>
                <span class="italics" v-html="item[1]"></span>
            </p>
            <p class="description" v-html="description"></p>
            <p class="x-rated" v-html="ageRated"></p>
        </div>
    </section>
</template>

<script>
    export default {
        name: "poster",
        props: ['posterUrl', 'posterMobileUrl', 'posterMobileHeight',
            'title', 'isStarted', 'writtenBy', 'description',
            'ageRated', 'opening', 'headerTop', 'theLink'],
        computed: {
            headerStyle() {
                return { top: `${this.headerTop}px` }
            }
        }
    }
</script>

<style lang="scss">
    .poster {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        background-repeat: no-repeat;
        flex-basis: 522px;
        background-size: 500px 450px;
        //background-position: top right;
        margin-bottom: 38px;

        .posterPic {
            width: 100%;
            margin-bottom: -50px;
        }

        h1 {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 40px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: 50px;
            letter-spacing: normal;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 6.66vw;
                line-height: 110%;
            }
        }

        .header-started-block {
            position: absolute;
            top: 86px;
            left: 0;
            width: 428px;
            height: 144px;
            transform: rotate(4deg);
            background-color: #d800d4;
        }

        .info-block {
            // margin-top: 376px;

            p {
                font-size: 20px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;

                @media all and (max-width: $screen-phone-max-width) {
                    font-size: 5.33vw;
                    line-height: 8vw;//6.66vw;
                }
            }

            .written-by {
                position: relative;

                color: #00c5f1;

                .italics {
                    font-style: italic;
                }
            }

            .description {
                margin-top: 10px;
                width: 400px;

                @media all and (max-width: $screen-phone-max-width) {
                    width: unset;
                    position: relative;
                }
            }

            .x-rated {
                margin-top: 8px;
                font-weight: 900;
                color: #5e0f6f;
            }

            @media all and (max-width: $screen-phone-max-width) {
                // margin-top: 80vw;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
           margin-bottom: 61px;
        }

        @media all and (min-width: 375px) and (max-width: $screen-phone-max-width) {
            flex-basis: 86.66vw;
            background-size: 100%;
            background-position: unset;
            margin-bottom: unset;
            padding-bottom: 100px;
        }

        @media all and (max-width: $screen-phone-middle-width) {
            flex-basis: 86.56vw;
            background-size: 100%;
            background-position: unset;
            margin-bottom: unset;
            padding-bottom: 100px;
        }
    }
</style>

