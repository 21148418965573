<template>
    <section class="contacts">
        <section-header :title="title" :hasLandmark="false" :hasLine="true" :sectionPrefix="`contacts`"></section-header>
        <p class="pretext">Приложение создает студия <a href="http://futurehistory.ru" target="_blank">«История будущего»</a>, авторы Project&nbsp;1917 и&nbsp;1968.DIGITAL</p>
        <div class="contacts-wrapper">
            <contact v-for="(contact, index) in items"
                     :key="index"
                     :type="contact.type"
                     :email="contact.email">
            </contact>
        </div>
        <a href="http://futurehistory.ru" target="_blank">
            <img class="fhlogo" src="~/static/images/fh-logo.gif" alt="">
        </a>
    </section>
</template>

<script>
    import Contact from '~/components/contact/contact.vue';
    import SectionHeader from '~/components/section-header/section-header.vue'

    export default {
        name: "partners",
        components: {
            Contact,
            SectionHeader
        },
        data() {
            let basePath = 'components.contacts';
            let title = this.getLocalized(basePath, 'title');
            let items = this.getLocalizedCollection(basePath, 'items');

            return {
                title,
                items
            }
        }
    }
</script>

<style lang="scss">
    .contacts {
        margin-top: 150px !important;
        padding-bottom: 62px;
        position: relative;

        .pretext {
            font-size: 20px;
            line-height: 140%;
            margin-top: 30px;
            margin-bottom: 20px;

            @media all and (max-width: $screen-phone-max-width) {
                font-size: 5.33vw;
            }
        }

        .fhlogo {
            margin-top: 20px;
            cursor: pointer;
            width: 80%;
            max-width: 350px;
            margin-left: -80px;

            @media all and (max-width: $screen-phone-max-width) {
                 margin-left: -54px;
            }
        }

        @include section-header-line (12px, -68%, 0, -41%, 0, -46%, 0, -47%, 127.4vw, 14.66vw);

        .contacts-wrapper {
            margin-top: 57px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                margin-top: 50px;
                // padding-left: 56px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                margin-top: 17px;
            }
        }
    }
</style>