<template>
    <article class="container" v-resize="onResize">
        <div class="loader"></div>
        <header-content></header-content>
        <section class="main-content" :style="style">
            <instructions></instructions>
            <posters></posters>
            <subscriber></subscriber>
            <team></team>
            <artists></artists>
            <partners></partners>
            <contacts></contacts>
            <footer-content></footer-content>
        </section>
    </article>
</template>
<script>
    import HeaderContent from '~/components/header/header.vue';
    import Instructions from '~/components/instructions/instructions.vue';
    import Posters from '~/components/posters/posters.vue';
    import Subscriber from '~/components/subscriber/subscriber.vue';
    import Team from '~/components/team/team.vue';
    import Artists from '~/components/artists/artists.vue';
    import Partners from '~/components/partners/partners.vue';
    import Contacts from '~/components/contacts/contacts.vue'
    import FooterContent from '~/components/footer/footer.vue';
    import $ from 'jquery';

    export default {
        name: 'Index',
        layout: 'default',
        components: {
            HeaderContent,
            Instructions,
            Posters,
            Subscriber,
            Team,
            Artists,
            Partners,
            Contacts,
            FooterContent
        },
        head() {
            return {
                title: this.$t('meta.title'),
                meta: [{ hid: 'description', name: 'description', content: this.$t('meta.description') }],
            }
        },
        data() {
            return {
                isChrome: false
            }
        },
        computed: {
            style() {
                return this.isChrome && {
                    backgroundAttachment: 'unset'
                }
            }
        },
        mounted() {
            this.isChrome = navigator.appVersion.indexOf("Chrome/") !== -1;

            this.$nextTick(() => {
                this.$nuxt.$loading.start();
                setTimeout(() => {
                    let loader = $('.loader');
                    loader.css('opacity', 0);
                    loader.css('display', 'none');
                    this.$nuxt.$loading.finish();
                    this.onResize();
                }, 10);
            });
        }
    }
</script>
<style lang="scss">
    .container {
        width: 100vw;
        background: $background-color;
        overflow-x: hidden;

        .loader {
            background: $background-color;
            position: fixed;
            opacity: 1;
            transition: opacity 1s ease;
            z-index: 10;
            width: 100vw;
            height: 100vh;
        }

        a {
            text-decoration: none;
            color: white;

            &:hover {
                text-decoration: underline;
            }
        }

        .main-content {
            background: url("/images/background.svg"), $background-color;
            background-attachment: fixed;

            color: white;

            > section {
                width: 1111px;
                margin: 0 auto;

                &.wide {
                    width: 100vw;
                }

                @include content-block-medias;
            }
        }
    }
</style>

