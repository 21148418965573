<template>
    <section class="subscriber wide">
        <div class="wide-content">
            <section-header :title="title" :hasLandmark="false" :hasLine="false" :sectionPrefix="`subscriber`"></section-header>
            <div class="form">
                <input type="email" v-model="email" :placeholder="inputPlaceholder"/>
                <button v-on:click="subscribe" :disabled="!isEmail || isSubscribing || isSubscribed" v-html="submit"></button>
                <div class="result-message" v-if="resultMessage" v-html="resultMessage"></div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios';
    import SectionHeader from '~/components/section-header/section-header.vue'

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    export default {
        name: 'Subscriber',
        components: {
            SectionHeader
        },
        data() {
            let basePath = 'components.subscriber';
            let formPath = `${basePath}.form`;

            return {
                title: this.getLocalized(basePath, 'title'),
                submit: this.getLocalized(formPath, 'submit'),
                inputPlaceholder: this.getLocalized(formPath, 'inputPlaceholder'),
                email: null,
                resultMessage: null,
                isSubscribed: false,
                isSubscribing: false
            }
        },
        computed: {
            isEmail() {
                return this.email && re.test(this.email);
            }
        },
        methods: {
            subscribe() {
                this.isSubscribing = true;

                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };

                axios.post('/api/v1/subscribe', `email=${this.email}`, config).then(
                    this.onSubscribed.bind(this, false),
                    this.onSubscribed.bind(this, true));
            },
            onSubscribed(isError, response) {
                let message = (response.data && response.data.message) || (isError && 'Произошла ошибка!') || '';
                this.isSubscribed = !isError;
                this.resultMessage = message;
                this.isSubscribing = false;
            }
        }
    }
</script>

<style lang="scss">
    .subscriber {
        position: relative;
        height: 286px;
        background-image: url("/images/subscriber-back.svg");
        background-repeat: no-repeat;
        margin-top: 100px !important;
        
        .wide-content {
            width: 1176px;
            margin: 0 auto;

            .section-header {
                padding-left: 30px;

                h1 {
                    line-height: 80px !important;

                    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                        line-height: 60px !important;
                    }

                    @media all and (max-width: $screen-phone-max-width) {
                        line-height: 9.33vw !important//35px !important;
                    }
                }

                @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                    padding-left: 42px;
                }

                @media all and (max-width: $screen-phone-max-width) {
                    display: block;
                    padding-left: unset;
                    width: 86.66vw;//325px;
                    margin: 0 auto;
                }
            }

            .form {
                margin-top: 20px;

                input {
                    box-sizing: border-box;
                    width: 837px;
                    height: 72px;
                    border: solid 3px #ffffff;
                    background-color: #001517;
                    border-right: none;
                    font-family: $osh;
                    font-size: 28px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 72px;
                    letter-spacing: normal;
                    color: #ffffff;
                    padding: 0 0 0 30px;
                    border-radius: 0;

                    &::placeholder {
                        font-family: $osh;
                        color: #ffffff;
                    }

                    &::-webkit-input-placeholder {
                        font-family: $osh;
                        color: #ffffff;
                    }
                    &:-moz-placeholder {
                        font-family: $osh;
                        color: #ffffff;
                        opacity:  1;
                    }
                    &::-moz-placeholder {
                        font-family: $osh;
                        color: #ffffff;
                        opacity:  1;
                    }
                    &:-ms-input-placeholder {
                        font-family: $osh;
                        color: #ffffff;
                    }

                    &:focus {
                        outline: none;
                    }

                    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                        width: 462px;
                        height: 35px;
                        line-height: 35px;
                        font-size: 20px;
                        padding: 0 0 0 42px;
                    }

                    @media all and (max-width: $screen-phone-max-width) {
                        width: 90.66vw;//340px;
                        height: 12.26vw;//46px;
                        padding: 0 0 0 1.86vw;//7px;
                        border: solid 3px #ffffff;
                        font-size: 5.33vw;//20px;
                        line-height: 12.26vw;//46px;
                    }
                }

                button {
                    width: 305px;
                    height: 90px;
                    font-size: 28px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #001517;
                    background-color: #00c5f1;
                    border: none;
                    text-transform: uppercase;
                    padding: 0;
                    margin-left: -4px;
                    cursor: pointer;
                    font-family: $osh;
                    border-radius: 0;

                    &:disabled {
                        cursor: not-allowed;
                    }

                    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                        width: 177px;
                        height: 52px;
                        font-size: 20px;
                    }

                    @media all and (max-width: $screen-phone-max-width) {
                        display: block;
                        width: 86.66vw;//325px;
                        height: 15.46vw;//58px;
                        margin: 11.2vw auto 0 auto; //42px
                        font-size: 5.33vw;//20px;
                    }
                }

                .result-message {
                    margin: 10px auto 0 auto;
                    width: 86.66vw;//325px;

                }

                @media all and (max-width: $screen-phone-max-width) {
                    margin-top: 11.2vw;//42px;
                }
            }

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
                width: 684px;
            }

            @media all and (max-width: $screen-phone-max-width) {
                width: 90.66vw;//340px;
            }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            height: 229px;
            background-image: url("/images/subscriber-back1365.svg");
        }

        @media all and (max-width: $screen-phone-max-width) {
            height: 60vw;
            background-image: url("/images/subscriber-back375.svg");
            background-size: 100vw;
            margin-top: unset !important;
        }
    }
</style>